@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";


    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

}

.open {
    transform: rotate(90deg);
}

.staff-info-container {
    min-width: 75vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;
    user-select: none;
}

.staff-info-content {
    width: 80%;
}

.left {
    background: white;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.button-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.horizontal {
    flex-direction: row;
}

.button {
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 8px;
    border-radius: 5px;
}

.documentsHidden {
    display: none;
}

.documentsVisible {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 8px;
}

.right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bio {
    color: rgba(50, 50, 50, 1);
    font-family: "Lato";
    font-size: 12px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    line-height: 17px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary-purple-color);
    min-height: 40px;
}

.title {
    font-size: 14px;
    color: black;
}

.training {
    font-size: 20px;
    font-weight: nomral;
    align-self: flex-start;
    margin-left: 8px;
    text-decoration: underline;
    cursor: pointer;
}

.profile-section-list-visible {
    display: flex;
    /* border: 1px solid var(--primary-blue-color); */
    flex: 1;
    width: 100%;
    text-align: center;
    flex-direction: column;
}

.profile-section-list-visible-block {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary-blue-color);
    align-items: center;
}

.profile-section-list-hidden {
    display: none;
}

.ListItem {
    background-color: white;
    height: 30px;
    color: grey;
    margin: 4px;
    font-weight: bold;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.list_item_name {
    flex: 2;
    text-align: left;
    padding-left: 16px;
    font-size: 12px;
}

.promote-button {
    color: var(--primary-blue-color);
    text-decoration: underline;
    text-decoration-color: var(--primary-blue-color);
}

.listItemcloseButton {
    color: grey;
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-self: flex-end;
    align-items: center;
    cursor: pointer;
}

.closeAndOpenButtonContainer {
    margin: 16px;
    align-self: flex-end;
    display: flex;
    cursor: pointer;
}
.closeButton {
    align-self: flex-end;
    cursor: pointer;
    margin-left: 8px;
}