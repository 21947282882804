.loadingSpinnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

.loadingText {
    font-weight: bold;
    font-size: large
}