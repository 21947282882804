.permission-request-cell-container {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--primary-purple-color);
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 8px;
}

.permission-request-cell-label {
    font-weight: bold;
}

.permission-request-cell-text {
    padding-left: 4px;
    padding-right: 16px;
}

.permission-request-cell-button-container {
    display: flex; 
    flex-direction: row;
    justify-self: flex-end;
    align-self: center;
    margin-left: auto;
    text-decoration: none;
    color: var(--primary-blue-color);
}

.permission-request-cell-button-view {
    color: var(--primary-blue-color);
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: var(--primary-blue-color);
    cursor: pointer;
}

.permission-request-cell-button-reject {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    cursor: pointer;
}

.permission-request-cell-button-approve { 
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 8px;
}

.permission-request-vertical-label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}