.document-approval-header {
    font-size: larger;
}

.document-approval-container { 
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 32px;
}

.documentList {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: scroll;
    margin-left: 32px;
    margin-right: 32px;
}

.document-approval-no-documents {
    font-size: 16px;
}