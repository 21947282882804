.welcome-page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.parallax {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.welcome-page-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;
    width: 70%;
    background-color: white;
}


@media only screen and (max-width: 600px) {
    .welcome-page-body {
        width: 100%;
        flex: 1;
        height: auto;
    }
}

.welcome-pdf-container {
    height: 95%;
    width: 80%;
}

.welcome-body-text {
    margin-top: 4px;
    font-weight: bold;
    font-size: 16px;
    text-align: start;
}

.welcome-header-text {
    margin-top: 4px;
    font-weight: bold;
    font-size: 24px;
    text-align: start;
}

.welcome-title {
    margin-top: 4px;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
}

.center {
    text-align: center;
    align-self: center;
}

.welcome-button {
    text-align: center;
    align-self: center;
    margin-top: 16px;
}