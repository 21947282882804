@media only screen and (max-width: 600px) {
    .list-add-button-container {
        border: 1px solid var(--primary-purple-color);
        display: flex;
        flex-direction: row;
        padding: 10px;
        width: 100%;
        margin-top: 10px;
        background-color: var(--primary-purple-color);
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 600px) {
    .list-add-button-container {
        border: 1px solid var(--primary-purple-color);
        background-color: var(--primary-purple-color);
        padding: 5px;
        display: flex;
        flex-direction: row;
        /* padding: 10px; */
        min-width: 25vw;
        margin-bottom: 10px;
        cursor: pointer;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
    }
}

.list-add-button-text {
    flex: 1;
    background: transparent;
    text-align: center;
    user-select: none;
    color: white
}

