@media only screen and (max-width: 600px) {
    .application-list-card {
        border: 1px solid #6A1C69;
        display: flex;
        flex-direction: row;
        margin-top: 10px; 
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
        padding: 8px;
    }
}

@media only screen and (min-width: 600px) {
    .application-list-card {
        border: 1px solid var(--primary-purple-color);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        cursor: pointer;
        border-radius: 5px;
        padding: 8px;
    }
}
.application-list-card:hover {
    background-color: var(--primary-purple-color);
    color: white;
}

.application-list-card-multilayer {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.application-list-card-text {
    flex: 1;
    text-align: center;
    background: transparent;
    justify-content: center;
}

