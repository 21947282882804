.disclaimer-page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.disclaimer-page-body {
    height: 100%;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.disclaimer-pdf-container {
    height: 70%;
    width: 75%;
}

.disclaimer-checkbox-container {
    margin-top: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.disclaimer-checkbox-label {
    font-size: larger;
    font-weight: bold;
    margin-right: 8px;
}

.disclaimer-checkbox {
    height: 20px;
    width: 20px;
}

@media only screen and (max-width: 600px) {
    .disclaimer-checkbox-container {
        margin-left: 16px;
        margin-right: 16px;
    }
}