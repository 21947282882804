*, *::before, *::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

.mainBody {
  flex-grow: 1;
  overflow: scroll;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

:root {
  --primary-blue-color: #00868B;
  --primary-purple-color: #692068;
  --filled-color: #25c082;
  --pending-color: #f2cb83;
  --noapps-color: #FF5F5F;
  --border-color: #eee;
  --bg-color: #ffffff;
  --neutral-color: #fff;
  --error-red: #FD9196;
}