.text {
    color: var(--primary-blue-color);
    max-width: 500px;
    cursor: default;
    user-select: none;
}

.body {
    font-size: 12px;
}
.header {
    font-size: 24px;
}

.subHeader {
    font-size: 16px;
    margin-bottom: 2px;
    margin-top: 2px;
}

.italicHeader {
    font-size: 16px;
    font-weight: bold;
}
