.permissions-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    border: 1px solid;
    padding: 32px;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 8px;
}

.permissions-button-image {
    width: 100px;
}

.permissions-button-text {
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}