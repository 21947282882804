.day-info-header {
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.noSessionsText {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.day-info-container {
    flex: 1;
    background-color: white;
    overflow: scroll;
}

.day-info-modal-close-button {
    justify-self: flex-end;
}