.permissions-requests-body {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.permissions-requests-header {
    margin-top: 16px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 16px;
}

.permissions-requests-list-container {
    display: flex; 
    flex-direction: column;
    overflow: scroll;
    margin-left: 32px;
    margin-right: 32px;
}
