.findASessionBody { 
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    min-height: 100vh;
}

.findASessionContainer { 
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 64px;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    margin-right: 64px;
}


@media (max-width: 600px) {
    .findASessionContainer { 
        flex-direction: column;
        margin-right: 8px;
        margin-left: 8px
    } 
}

@media only screen and (max-width: 600px) {
    .listContainer {
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 600px) {
    .listContainer {
        margin: 0px;
        display: flex;
        width: 60%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.listDateHeading {
    font-size: 1.2em;
    color: #6A1C69;
    align-self: flex-start;
}

.findASessionTitle {
    font-size: 1.5em;
    color: '#6A1C69';
}

.find-session-day-info-container {
    margin-left: 32px;
    height: 80vh;   
    overflow: scroll;
}
@media (max-width: 600px) {
    .find-session-day-info-container { 
        display: none;
    } 
}

.find-session-filter-bar-container {
    display: flex;
    flex-direction: row;
    margin-left: 64px;
    margin-top: 16px;
}

.find-session-filter-bar-filter-container {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
}

.find-session-filter-bar-button-container {
    align-self: center;
    margin-left: 8px;
}