.pendingContainer {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
}

.pendingContainerShowingStaffInfo {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}