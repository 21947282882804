.drag-upload-container {
    margin: 8px;
    margin-bottom: 16px;
}

.modal-add-document-input {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: large;
    width: 100%;
    border: 0px;
    border-bottom: 1px var(--primary-blue-color) solid;
    padding-left: 4px;
    padding-right: 4px;
}