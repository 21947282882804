.profile-header-stack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    margin-left: 4px;
}

.profile-header-left-top {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 8px;
}

.profile-header-image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}

.profile-header-image:hover {
    cursor: pointer;
}

.profile-header-name {
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
    display: flex;
    align-items: center;
}

.edit_button {
    margin-left: 8px;
    height: 16px;
    width: 16px
}

.profile-header-approval {
    height: 20px;
    margin-left: 8px;
}
.profile-header-approval-text {
    font-size: 14px;
}

.staff-info-type-select {
    margin-bottom: 1vh;
    border-radius: 1vh;
    border: 1px solid var(--primary-purple-color);
    padding-left: 16px;
    padding-right: 16px;
    font-family: inherit;
    background-color: white;
    margin-left: 4px;
}

.profile-header-staff-type-label {
    font-size: 16px;    
}