
.dasboardBody {
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    min-height: 100vh;
}

.staff-dashboard-container { 
    background-color: transparent;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: scroll;
    margin: 32px;
}

@media (max-width: 600px) {
    .staff-dashboard-container { 
        flex-direction: column;
    } 
}

.staff-dashboard-session-list-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin: 32px;
    margin-bottom: 16px;
}

.staff-dashboard-header-row {
    display: flex;
    direction: row;
    justify-content: flex-start;
}

@media (max-width: 600px) {
    .staff-dashboard-session-list-container{ 
        flex-direction: column;
        width: 90%
    } 
}

.emptySessionsContainer {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    font-size: larger;
    font-weight: bold;
    flex-direction: column;
    align-items: center;
}

.find-sessions-button {
    background-color: var(--primary-purple-color);
    color: white;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 16px
}