.assign-staff-container {
    width: 100%;
    margin-left: 8px;
    margin-right: 8px;
    overflow: scroll;
}

.assign-staff-back-button {
    align-self: flex-start;
    margin-right: 16px;
    color: var(--primary-purple-color);
    text-decoration: underline;
    text-decoration-color: var(--primary-purple-color);
    cursor: pointer;
}

.assign-staff-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 8px;
    margin-right: 8px;
}

.assign-staff-list-container {
    flex: 1;
}