.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: white;
    background-color: white;
    
}

.practiceInfo {
    width: 60vw;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
}

.practice-info-columns {
    display: flex;
    flex-direction: row;
}

.practice-info-input {
    margin-bottom: 1vh;
    height: 4vh;
    width: 80%;
    border: 1px solid var(--primary-purple-color);
    padding-left: 16px;
    padding-right: 16px;
    font-family: inherit;
    background-color: white;
}

.doubleHeight {
    height: 8vh;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.practice-info-contact {
    display: flex; 
    flex-direction: column;
    align-self: center;
    width: 100%;
}

.practice-info-contact-header {
    align-self: center;
}

.practiceInfoSectionFormsItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 auto;
    flex-wrap: wrap;
    width: 90%;
}

.practiceInfoSecion {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.practiceInfoButtonContainer {
    background-color: white;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.topBar {
    background: var(--primary-blue-color);
    width: 100%;
    height: 40px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 16px;
}

.top {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.topLeftPractice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    color: white;
    padding-left: 16px;
}

.topRight {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-self: flex-end;
    justify-content: flex-end;
    align-items: flex-start;
    color: white;
    padding-right: 16px;
    padding-top: 8px;
    font-weight: bold;
    cursor: pointer;
}

.location_name {
    font-weight: bold;
    font-size: large;
    height: 100%;
    color: white;
}