.burger-menu-container-open {
    animation-name: open;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    position: absolute;
    left: 100vw;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: white;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
}

.burger-menu-container-close {
    animation-name: close;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    position: absolute;
    left: 100vw;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: white;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}



@keyframes open {
    from {left: 100vw}
    to {left: 0vw} 
  }


@keyframes close {
    from {left: 0vw}
    to {left: 100vw}
  }

.burger-menu-item-list {
    display: flex;
    flex-direction: column;

}

.burger-menu-item {
    color: grey;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
    cursor: pointer;
}