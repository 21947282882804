.sessionCard {
    height: 130px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-color);
}

.status_filled {
    height: 15%;
    width: 100%;
    font-weight: bold;
    background-color: var(--filled-color);
    border: 1px solid var(--filled-color);
    color: white;
    display: flex;
    align-items: center;
    padding-left: 8px;
}
.status_pending {
    height: 15%;
    width: 100%;
    font-weight: bold;
    background-color: var(--pending-color);
    border: 1px solid var(--pending-color);
    color: white;
    display: flex;
    align-items: center;
    padding-left: 8px;
}
.status_no_applicants {
    height: 15%;
    width: 100%;
    font-weight: bold;
    background-color: var(--noapps-color);
    border: 1px solid var(--noapps-color);
    color: white;
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.status_hidden {
    height: 15%;
    width: 100%;
    font-weight: bold;
    background-color: lightgrey;
    border: 1px solid lightgrey;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.p {
    margin: 2px;
}