@media only screen and (max-width: 600px) {
    .listCardContainer {
        border: 1px solid #6A1C69;
        display: flex;
        flex-direction: row;
        margin-top: 10px; 
        align-items: center;
    }
}

@media only screen and (min-width: 600px) {
    .listCardContainer {
        border: 1px solid var(--primary-purple-color);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
}

.listCardMultilayer {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.staffListCardText {
    flex: 1;
    text-align: center;
    background: transparent;
    justify-content: center;
}

