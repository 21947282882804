.rotaBody {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.rotaContainer { 
    margin-bottom: 16px;
    margin-left: 64px;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    margin-right: 64px;
    flex: 1;
}

@media (max-width: 600px) {
    .rotaContainer { 
        flex-direction: column;
        margin-right: 8px;
        margin-left: 8px
    } 
}

.rota-page-day-info {
    display: flex;
    flex: 1;
    margin-left: 8px;
}

.rota-filter-bar-container {
    display: flex;
    flex-direction: row;
    margin-left: 64px;
    margin-top: 16px;
}

.rota-filter-bar-filter-container {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
}

.rota-filter-bar-button-container {
    align-self: center;
    margin-left: 8px;
}