.staffPageContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: lightcyan;
}

.staff-page-info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
}