.bio-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
}

.bio-text-container {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 18px;
    border: 1px solid var(--primary-blue-color);
    width: 100%;
    border-radius: 5px;
    padding: 4px;
}

.bio-text {
    font-weight: bold;
    text-align: center;
}

.bio-button-edit {
    justify-self: center;
    width: fit-content;
    background-color: var(--primary-blue-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 8px;
    border-radius: 5px;
}