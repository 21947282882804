.editAccountFormContainer {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    margin-left: 32px;    
    margin-right: 32px;
    align-items: center;
}

.editAccountTable {
    border: none;
    margin-top: 32px;
}
.edit-account-hint {
    color: var(--primary-purple-color);
    font-size: 24px;
    margin-left: 32px;
    margin-right: 32px;
    text-align: center;
}
.edit-account-body {
    height: 100vh;
    display: flex;
    flex-direction: column;
}