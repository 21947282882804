.staffNewBody {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.staffNewContainer {
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        flex: 1;
        margin-top: 32px;
        margin-left: 32px;
        margin-right: 32px;
        margin-bottom: 16px;
}
