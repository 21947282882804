.accountBody {
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    height: 100vh;
}

.accountContainer {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
}

.leftColumn {
    display: flex;
    flex-direction: column;
}