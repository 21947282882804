.floating-notifications-panel {
    position: absolute;
    top: 9vh;
    right: 40px;
    height: 500px;
    width: 500px;
    background-color: white;
    box-shadow: 0 0 1px 1px lightgray;
    z-index: 10;
    display: flex;
    flex-direction: column;
}

.floating-notifications-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
}

.floating-notifications-mark-read-button {
    color: white;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

.floating-notifications-title { 
    font-weight: bold;
    margin-left: 16px;
}

.floating-notifications-close-button { 
    justify-self: flex-end;
    margin-right: 16px;
    cursor: pointer;
}

.floating-notifications-panel-empty {
    align-self: center;
    justify-self: center;
}

.floating-notifications-list {
    flex: 1;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}