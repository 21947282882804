.sessionContainer {
    min-width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: white;
    padding-bottom: 16px;
}

@media only screen and (max-width: 600px) {
.sessionContainer {
        width: 90vw
    }
}

.session-header-container {
    width: 100%;
}

.sessionInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.session-info-header {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.dateContainer {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    flex-direction: column;
    flex: 1;
}

.noApps {
    flex-direction: row;
    background: var(--noapps-color);
    height: 8px;
    color: black;
    justify-content: center;
}

.pend {
    flex-direction: row;
    background: var(--pending-color);
    height: 8px;
    color: black;
    justify-content: center;
}

.fill {
    flex-direction: row;
    background: var(--filled-color);
    height: 8px;
    color: black;
    justify-content: center;
}

.sessionTop {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.sessionBottom {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.dropdown {
}

.sessionBottomLeft {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: black;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 16px;
}

.sessionBottomRight {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-start;
    color: black;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 16px;
}

.jobNumber {
    margin-left: 16px;
    font-weight: normal;
    font-size: large;
    font-weight: bold;
}

.applyButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding-bottom: 16px;
}

.session_staff_info {
    width: 100%;
}

.session-info-practice-name {
    font-size: 20px;
    text-decoration: underline;
}