.create-session-permission-requests-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.create-session-permission-requests-body {
    padding: 32px;
}

.locality-filter-bar-container {
    display: flex;
    width: 100%;
}

.locality-filter-bar-filter-container {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
}

.permission-request-tab-container {
    display: flex;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.5);

}

.permission-request-tab-item {
    margin-right: 16px;
    cursor: pointer;
    font-weight: bold;
}

.permission-request-tab-item:hover {
    text-decoration: underline;
    text-decoration-color: var(--primary-blue-color);;
}

.selected {
    color: var(--primary-blue-color);
    text-decoration: underline;
    text-decoration-color: var(--primary-blue-color);;
}

.create-session-permissions-no-locality {
    font-weight: 200;
    padding-top: 16px;
}