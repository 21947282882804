.number-card-container {
    border: 1px solid black;
    height: 100px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.number-card-title {
    margin: 8px;
    align-self: flex-start;
    font-weight: 500;
}

.number-card-number {
    align-self: center;
    font-weight: bold;
    font-size: 32px;
}