.cellContainer {
    background: white;
    padding: 16px;
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    height: 170px;
    width: 142px
}

.staffImage {
    margin: 15px;
    margin-bottom: 8px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}

.staffName {
    font-size: 10px;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 8px;
    text-align: center;
}

.notificationDot {
    background-color: var(--primary-purple-color);
    border-radius: 10px;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    vertical-align: middle;
    font-size: 14px;
    font-weight: bold;
}

.gone {
    display: none;
}
.staff-cell-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.staff-cell-button-assign {
    background-color: var(--primary-blue-color);
    color: white;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
}