.modal {
    position: fixed;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.show {
    display: flex;
    
}

.hide {
    display: none;
}

.modalContainer {
    background-color: white;
    overflow: hidden;
}
.loadingContainer {
    position: absolute;
    display: flex; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center; 
    align-items: center;
    background-color: rgba(0.3, 0.3, 0.3, 0.4);
}

.loading {
    background-color: white;
    padding: 16px;
    border-radius: 10px;
}