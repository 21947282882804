/*

Created by Matej Kovac
http://moodydev.io

*/

/* FONT IMPORTS */
.calendarContainer {
  flex: 2;
  background-color: white;
  border-radius: 0.1vw;
  position: relative;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  background: var(--bg-color);
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .DateHeader {
    font-size: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  height: 70px;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
  height: 25px;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendarBody {
  border-left: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}


.calendar .body .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

/* .Day .disabled {
    color: blue;
} */

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: black;
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.calendar-header {
  display: flex;
  flex-direction: row;
  background-color: var(--primary-blue-color);
  width: 100%;
  justify-content: space-around;
  margin-bottom: 8px;
}

.DateHeader {
  flex-basis: 1;
  max-width: 100%;
  text-align: center;
  height: 48px;
  color: white;
  display: flex;
  align-items: center;
}

.filter-bar {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
}

.filter-bar-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.filter-practice-dropdown {
  background-color: white;
  border: 1px solid var(--primary-purple-color);
  border-radius: 5px;
  height: 4vh;
  padding-left: 16px;
  padding-right: 16px;
  font-family: inherit;
  width: 25vw;
  margin-left: 8px;
}

.filter-bar-title-container {
  display: flex;
  flex-direction: row;
}
.filter-icon {
  height: 20px;
}

.chevronImage {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.rota-disabled {
  height: 100%;
  width: 100%;
  position: absolute; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}