.notification-item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 16px;
    margin-right: 16px;
}

.notification-item-read-icon-container {
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.notification-item-read-icon {
    height: 16px;
    width: 16px;
    background-color: var(--primary-blue-color);
    border-radius: 50%;
}

.notification-item-text {
    font-size: 16px;
}