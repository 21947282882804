.coloredButton {
    height: 30px;
    width: 100px;
    color: white;
    border: 0px;
    margin-top: 0px;
    margin-right: 0px;
    display: flex;
    justify-content: center; 
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}

.coloredButton:hover {
    text-decoration: underline currentColor;
}

