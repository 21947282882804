.staffPageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;    
}

.search-bar {
    font-size: 20px;
    border: 2px solid var(--primary-blue-color);
    border-radius: 4px;
    width: 95%;
    padding: 4px;
    margin-bottom: 4px;
}

.staff-list-container {
    display: flex;
    flex-direction: column;
}

.staff-list-filter-type {
    display: flex;
    flex-direction: column;
}

.staff-list-filter-type-toggles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.staff-list-filter-toggle {
    text-align: center;
    padding-right: 8px;
    padding-left: 8px;
}

.staff-list-filter-type-toggles-table {
    border: none;
}

.staff-list-filter-label {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.staff-list-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: scroll;
    gap: 8px;
    display: flex;
    flex-direction: row;
}