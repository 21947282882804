
.add-system-item-container {
    padding: 8px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-left: 8px;
    margin-right: 8px;

    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selected {
    border-color: var(--primary-blue-color);
    border-width: 2px;
}

.add-system-item-image {
    height: 32px;
    width: 32px;
}

.add-system-item-text {
    font-weight: bold;
}

