.analytics-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.analytics-body {
    padding: 16px;
}

.analytics-header {
    font-size: 32px;
}

.analytics-section {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.analytics-filter-bar-container {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}

.analytics-filter-bar-filter-container {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
}