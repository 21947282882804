.footer {
    background-color: var(--primary-blue-color);
    width: 100%;
    align-self: flex-end;
    display: flex; 
    flex-direction: row;
    justify-self: flex-end;
    justify-content: flex-end;
    align-items: center;
    padding-left: 10vw;
    padding-right: 10vw;
    min-height: 5vh;
    color: white;
    font-weight: 400;
}