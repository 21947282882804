.permissions-home-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.permissions-home-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    align-items: center;
}

.permissions-home-button-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
}