@media only screen and (max-width: 600px) {
    .listCardContainer {
        border: 1px solid #6A1C69;
        display: flex;
        flex-direction: row;
        padding: 10px;
        width: 100%;
        margin-top: 10px;
    }
}

@media only screen and (min-width: 600px) {
    .listCardContainer {
        border: 1px solid #6A1C69;
        padding: 5px;
        display: flex;
        flex-direction: row;
        /* padding: 10px; */
        min-width: 25vw;
        margin-bottom: 10px;
        cursor: pointer;
        border-radius: 5px;
        background-color: white;
    }
}

.listCardContainer:hover {
    background-color: var(--primary-purple-color);
    color: white;
}

.listCardText {
    flex: 1;
    background: transparent;
    text-align: left;
    user-select: none;
}

