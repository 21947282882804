.document-approval-cell-container {
    display: flex;
    flex-direction: row;
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 8px;
    border-width: 1px;
    border-style: solid;
}

.document-approval-cell-label {
    font-weight: bold;
}

.document-approval-cell-text {
    padding-left: 4px;
    padding-right: 16px;
}

.document-approval-cell-button-container {
    display: flex; 
    flex-direction: row;
    justify-self: flex-end;
    margin-left: auto;
    text-decoration: none;
    cursor: pointer;
    color: var(--primary-blue-color);
}

.document-approval-cell-button-container:hover {
    text-decoration: underline;
    text-decoration-color: var(--primary-blue-color);
}

.document-approval-cell-button-view {
    color: var(--primary-blue-color);
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: var(--primary-blue-color);
    cursor: pointer;
}

.document-approval-cell-button-reject {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    cursor: pointer;
}

.document-approval-cell-button-approve { 
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 8px;
}