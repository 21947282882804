.location-list {
    width: 100%;
    flex: 1;
    padding: 16px;
    padding-left: 0px;
}

.location-list-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    border: 1px solid var(--primary-purple-color);
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 8px;
}

.location-list-item-text {
    text-decoration: none;    
    cursor: pointer;
}

.location-list-item-text:hover {
    text-decoration: underline;
    text-decoration-color: var(--primary-blue-color);
}

.location-list-item-request-text {
    font-weight: bold;
    color: var(--primary-blue-color);
    text-decoration: none;    
    cursor: pointer;
}

.location-list-item-request-text {
    text-decoration: underline;
    text-decoration-color: var(--primary-blue-color);
}