.actions-cell-body {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.actions-cell-container {
    display: flex;
    flex-direction: column;
    padding: 4px;
}

.actions-cell-button-body {
    display: flex;
    flex-direction: row;
    border: 1px solid blue;
    cursor: pointer;
    margin: 4px;
}
