.approved-unapproved-card-container {
    border: 1px solid black;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.approved-unapproved-card-title {
    margin: 8px;
    align-self: flex-start;
    font-weight: 500;
}

.approved-unapproved-card-number {
    align-self: center;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

.approved-unapproved-card-number-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.approved-unapproved-card-approved-label {
    color: green;
}

.approved-unapproved-card-total-label {
    color: black;
}