
.editAccountInput {
    margin-bottom: 1vh;
    height: 4vh;
    border: 1px solid lightgray;
    padding-left: 16px;
    padding-right: 16px;
    font-family: inherit;
    background-color: white;
}

.edit-account-label-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 4px;
}

.edit-account-label {
    font-size: 20px;
}

.editAccountBioInput {
    max-lines: 10;
    height: 100px;
    margin-bottom: 32px;
    border: 1px solid lightgray;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    font-family: inherit;
    background-color: white;
}

.editAccountSelect {
    margin-bottom: 1vh;
    height: 4vh;
    border: 1px solid lightgray;
    padding-left: 16px;
    padding-right: 16px;
    font-family: inherit;
    background-color: white;
}

.edit-account-button {
    background-color: var(--primary-purple-color);
    border: none;
    color: white;
    width: 70%;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 8px;
    cursor: pointer;
    align-self: center;
    height: 48px;
}

.edit-account-button-container {
    display: flex;
    justify-content: center;
}