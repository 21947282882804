.adminBody {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.adminContainer {
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        flex: 1;
        margin-top: 32px;
        margin-left: 32px;
        margin-right: 32px;
        margin-bottom: 16px;
}

.practiceContainer {
    display: flex;
    flex-direction: column;
    width: 30%;
    overflow: scroll;
    align-items: flex-start;
}

.practiceList {
    padding: 0px;
}  

.staffContainer {
    display: flex;
    flex-direction: column;
    width: 65%;    
    overflow: scroll;
}

.seperatorLine {
    height: 3px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 95%;
}

.staffSearchContainer {
    
}

.staffResults {

}
.staff-container-header {
    display: flex;
    align-items: center;
}

.staff-container-header-plus {
    height: 32px;
    width: 32px;
    margin-left: 16px;
    cursor: pointer;
}