
.day {
    position: relative;
    border-top: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      overflow: hidden;
      cursor: pointer;
      background: var(--neutral-color);
      transition: 0.25s ease-out;
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
      height: 10vh;
      width: 10vh;
      background: white;
      color: #682467;

      color: grey;
      display: flex;
      flex-direction: column;
      padding-left: 4px;
      padding-top: 4px;
    }

.day-disabled {
  position: relative;
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: white;
  transition: 0.25s ease-out;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  height: 10vh;
      width: 10vh;
  color: grey;
  display: flex;
  flex-direction: column;
  padding-left: 4px;
      padding-top: 4px;
}
.day-past {
    position: relative;
    border-top: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      overflow: hidden;
      cursor: pointer;
      background: white;
      transition: 0.25s ease-out;
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
      height: 10vh;
      width: 10vh;
      color: grey;
      display: flex;
      flex-direction: column;
      padding-left: 4px;
      padding-top: 4px;
    }




.day-selected {
      position: relative;

      border-right: 1px solid var(--border-color);
      border-top: 1px solid var(--border-color);
      overflow: hidden;
      cursor: pointer;
      background: white;
      transition: 0.25s ease-out;
      border: 1px solid black;
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
      height: 10vh;
      width: 10vh;
      color: grey;
      display: flex;
      flex-direction: column;
      padding-left: 4px;
      padding-top: 4px;
    }

    .text-enabled {
      color: black;
    }

    .text-disabled {
      color: grey;
    }

    .statusIndicator {
      height: 2px; 
      margin-right: 2px;
    }

    .status-no {
      background-color: var(--noapps-color);
    }

    .status-pend {
      background-color: var(--pending-color);
    }

    .status-fill {
      background-color: var(--filled-color);
    }

    .sessionContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;
    }
    .sessionCount {
      color: #682467;
      font-weight: normal;
      font-size: 10px;
      text-align: left;
      margin: 2px;
    }

    .hidden {
      display: none;
    }
    
    .cell-add-button {
      visibility: hidden;
    }

    .cell-add-button-visible {
      visibility: visible;
    }