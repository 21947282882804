.gdpr-page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.gdpr-page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.gdpr-pdf-container {
    height: 95%;
    width: 80%;
}