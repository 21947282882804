.navBarContainer {
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: white;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
    z-index: 10;
}

@media only screen and (min-width: 600px) {
    .burgerMenuButton {
        height: 19;
        width: 25px;
        margin-right: 16px;
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .burgerMenuButton {
        height: 19;
        width: 25px;
        margin-right: 16px;
        display: block;
        position: absolute;
        right: 0px;
        z-index: 10;
    }
}

@media only screen and (min-width: 600px) {
    .menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

@media only screen and (max-width: 600px) {
    .menu {
        display: none;
    }
}

.nav-bar-logo {
    margin-left: 32px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 100%;
    cursor: pointer;
}

.burgerMenuFull {
    position: absolute;
    height: 200vh;
    width: 80%;
    right: 0px;
    background-color: #05868B;
    display: flex;
}

.burgerMenuList {
    height: 100%;
    display: flex;
    color: white;
    width: 200px;
    padding-left: 0px;
}

.burgerMenuListItem {
    position: absolute;
    color: white;
    height: 40px;
    top: 100px;
}

.nav-bar-new-notification-icon {
    height: 22px;
    margin-left: 8px;
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
}

.nav-bar-new-button {
    color: grey;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
    text-decoration: none;
}

.nav-bar-new-button:hover {
    text-decoration: underline;
}

.nav-bar-new-button:hover {
    text-decoration: underline;
}

