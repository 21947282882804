.staff-approval-body {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.staff-approval-container {
    flex: 1;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 32px;
}