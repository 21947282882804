.sessionPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.session-page-body {
    width: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}