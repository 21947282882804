.modal-add-container {
    margin: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-add-title {
    font-size: large;
}

.modal-add-list-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 32px;
}

.modal-add-button {
    background-color: var(--primary-purple-color);
    color: white;
    width: 70%;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 8px;
    cursor: pointer;
}

.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}