.account-type-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
}

.account-type-modal-title {
    font-size: large;
    word-wrap: normal;
}

.account-type-button-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.account-type-button {
    background-color: var(--primary-purple-color);
    color: white;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    margin: 4px
}

.no {
    height: auto;
    background-color: white;
    color: var(--primary-purple-color);
    border: 1px solid var(--primary-purple-color);
}